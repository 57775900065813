<script setup lang="ts" name="SailingScheduleMobileFilters">
import { computed, ref } from 'vue';
import { useVModel } from '@vueuse/core';

import BaseButton from '~/components/BaseButton.vue';
import PublicSailingScheduleAddTradesOverlay from '~/components/PublicSailingScheduleAddTradesOverlay.vue';
import SailingScheduleMobileFiltersOverlay from '~/components/SailingScheduleMobileFiltersOverlay.vue';

import type {
  ISailingScheduleOption,
  ISailingScheduleQuery,
  IStatisticsTradeLane,
} from '~/types';

const props = defineProps<{
  modelValue: ISailingScheduleQuery;
  activeFilters: unknown[];
  addTrade?: boolean;
  tradelanes: IStatisticsTradeLane[];
  loadingPorts: ISailingScheduleOption[];
  dischargePorts: ISailingScheduleOption[];
  vessels: ISailingScheduleOption[];
}>();

const emit = defineEmits<{
  (e: 'update:modelValue'): void;
  (e: 'applyTradesSelection'): void;
  (e: 'clearFilters'): void;
}>();

const addTradesOverlay = ref(false);
const filtersOverlay = ref(false);

const query = useVModel(props, 'modelValue', emit);

const filterVariant = computed(() =>
  props.activeFilters.length ? 'btn-primary' : 'btn-secondary',
);
</script>

<template>
  <div>
    <div
      class="flex items-center justify-between gap-2 rounded bg-white p-2 drop-shadow"
    >
      <div class="flex w-full justify-between">
        <BaseButton
          v-if="activeFilters.length"
          inverted
          variant="btn-danger"
          label="Clear Filters"
          @click="emit('clearFilters')"
        />

        <div class="ml-auto">
          <BaseButton
            v-if="addTrade"
            icon="mdi:plus"
            variant="btn-secondary"
            @click="addTradesOverlay = true"
          />
        </div>
      </div>

      <div class="ml-auto">
        <BaseButton
          icon="mdi:mixer-settings"
          :variant="filterVariant"
          @click="filtersOverlay = true"
        >
          Filter
        </BaseButton>
      </div>
    </div>

    <SailingScheduleMobileFiltersOverlay
      v-model:query="query"
      v-model:overlay="filtersOverlay"
      :vessels="vessels"
      :tradelanes="tradelanes"
      :discharge-ports="dischargePorts"
      :loading-ports="loadingPorts"
    />

    <PublicSailingScheduleAddTradesOverlay
      v-if="addTrade"
      v-model="addTradesOverlay"
      @applyTradesSelection="emit('applyTradesSelection')"
    />
  </div>
</template>
