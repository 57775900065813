<script setup lang="ts" name="SupportIndex">
import { useProfileStore } from '~/store';

import BaseButton from '~/components/BaseButton.vue';
import BasePage from '~/components/BasePage.vue';
import ContactCard from '~/components/ContactCard.vue';
import SupportTheLegalInformation from '~/components/SupportTheLegalInformation.vue';

const showTour = () => {
  useProfileStore().resetTour();
};

const isPublic = useProfileStore().isPublic;

const contacts = [
  {
    title: 'Support',
    linkUrl: 'mailto:myg2support@g2ocean.com',
    linkText: 'myg2support@g2ocean.com',
  },
  {
    title: 'Feedback',
    linkUrl: 'mailto:myg2feedback@g2ocean.com',
    linkText: 'myg2feedback@g2ocean.com',
  },
];

const legalInfoLinks = [
  {
    label: 'Privacy Policy',
    href: 'https://www.g2ocean.com/corporate-responsibility/privacy-notice/',
  },
  {
    label: 'Terms of Service',
    href: '/pdfs/TermsandConditionsMyG2.pdf',
  },
  {
    label: 'Cookie Policy',
    to: { name: isPublic ? 'PublicUserCookiePolicy' : 'CookiePolicy' },
  },
];
</script>

<template>
  <BasePage title="Support" with-aside with-pattern>
    <ContactCard
      v-for="contact in contacts"
      v-bind="contact"
      :key="contact.title"
    />
    <template #aside>
      <SupportTheLegalInformation :links="legalInfoLinks" />

      <BaseButton
        v-if="!isPublic"
        class="hidden lg:block"
        inverted
        @click="showTour"
      >
        Start the tutorial
      </BaseButton>
    </template>
  </BasePage>
</template>
