<script setup lang="ts" name="NavBarOrganisationSelect">
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useOrganisationsStore, useProfileStore } from '~/store';

import { injectSidebarState } from '~/features/useSidebarState';

import Icon from '~/components/Icon.vue';
import NavBarOrganisationSelectOption from '~/components/NavBarOrganisationSelectOption.vue';
import OrganisationSelectButton from '~/components/OrganisationSelectButton.vue';
import XDropdown from '~/components/XDropdown.vue';

import type { IOrganisation } from '~/types';

const emit = defineEmits<{ (e: 'close'): void }>();
const router = useRouter();
const route = useRoute();
const profileStore = useProfileStore();
const organisationStore = useOrganisationsStore();

const sidebarState = injectSidebarState();

const isG2OEmployee = computed(() => profileStore.isG2OEmployee);
const organisations = computed<IOrganisation[]>(
  () => organisationStore.organisations,
);

const currentId = ref<string | null>(
  (route.params.organisationId as string) ||
    organisationStore.organisation?.id ||
    null,
);

const organisation = computed<IOrganisation | null>(() =>
  organisationStore.getById(currentId.value),
);

const name = computed(() => organisation.value?.name || 'All Organisations');

const orgSearch = ref('');
const hasOneOrganisation = computed(
  () => organisations.value.length <= 1 && !isG2OEmployee.value,
);

const availableOrganisations = computed(() => {
  const list =
    organisations.value.map((org) => ({
      organisationId: org.id,
      text: org.name,
    })) || [];

  return orgSearch.value
    ? list.filter(({ text }) =>
        text.toLowerCase().includes(orgSearch.value.toLowerCase()),
      )
    : list;
});

const onClickOutside = () => {
  orgSearch.value = '';
};

const onClose = () => {
  orgSearch.value = '';
};

const onClearSearch = (close: () => void) => {
  if (orgSearch.value) {
    orgSearch.value = '';
    return;
  }

  close();
};

const onSelectAllOrganisations = async (close: () => void) => {
  currentId.value = null;
  organisationStore.unsetCurrentOrganisation();

  close();

  await router.push({
    name: 'AdminOrganisationsIndex',
  });

  sidebarState.setSidebarVisible(false);

  emit('close');
};

const onSelectOrganisation = async (
  organisationId: OrganisationId,
  close: () => void,
) => {
  const name =
    currentId.value === null ? 'OrganisationHomeIndex' : (route.name as string);

  currentId.value = organisationId;

  close();

  await router.push({
    name,
    params: {
      ...route.params,
      organisationId,
    },
  });

  sidebarState.setSidebarVisible(false);

  emit('close');
};

const onToggle = (toggle: () => void) => {
  toggle();

  sidebarState.openSidebar();
};

watch(
  () => route.params.organisationId,
  (organisationId) => {
    currentId.value =
      (organisationId as string) || organisationStore.organisation?.id || null;
  },
);
</script>

<template>
  <XDropdown :teleport="false" @close="onClose" @click-outside="onClickOutside">
    <template #trigger="{ toggle, isOpen }">
      <OrganisationSelectButton
        :name="name"
        :closed="sidebarState.closed.value"
        :open="isOpen"
        :hasOneOrganisation="hasOneOrganisation"
        @toggle="onToggle(toggle)"
      />
    </template>
    <template #default="{ close }">
      <div
        class="w-[279px]"
        :class="{
          hidden: sidebarState.transitioned.value,
        }"
      >
        <label
          class="mx-2 mb-2 flex items-center border-b border-charcoal-2 pb-2 pt-4 text-charcoal-2 focus-within:border-primary-8 focus-within:text-primary-8"
        >
          <div
            class="pointer-events-none ml-1 mr-2 flex items-center justify-start"
          >
            <Icon icon="mdi:magnify" />
          </div>
          <input
            ref="searchInputRef"
            v-model="orgSearch"
            type="text"
            maxlength="128"
            data-test="search-field"
            class="w-full p-0 text-sm text-black focus:outline-none focus-visible:ring-transparent"
            placeholder="Search"
            @keydown.esc="onClearSearch(close)"
          />
        </label>

        <NavBarOrganisationSelectOption
          v-if="isG2OEmployee"
          text="All Organisations"
          :is-active="!currentId"
          @select="onSelectAllOrganisations(close)"
        />
        <div class="max-h-[230px] overflow-y-auto">
          <div
            v-if="!availableOrganisations.length"
            class="px-3 py-4 text-black"
          >
            No Organisations found.
          </div>

          <div
            v-for="item in availableOrganisations"
            :key="item.organisationId"
          >
            <NavBarOrganisationSelectOption
              :text="item.text"
              :is-active="item.organisationId === organisation?.id"
              @select="onSelectOrganisation(item.organisationId, close)"
            />
          </div>
        </div>
      </div>
    </template>
  </XDropdown>
</template>
