<script setup lang="ts" name="PublicSidebar">
import { computed } from 'vue';

import { usePublicNavBarLinks } from '~/features/useNavBarLinks';

import Icon from '~/components/Icon.vue';
import LogoG2OVertical from '~/components/LogoG2OVertical.vue';
import NavBarLink from '~/components/NavBarLink.vue';

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const activeLinks = computed(() => usePublicNavBarLinks());
</script>

<template>
  <div class="overflow-y-auto px-2 text-white">
    <div class="flex h-16 w-full items-center">
      <LogoG2OVertical class="ml-4" />
      <button
        class="ml-auto flex h-8 w-8 items-center justify-center rounded-full hover:bg-primary-6 lg:hidden"
        @click.stop="emit('close')"
      >
        <Icon icon="ic:round-close" />
      </button>
    </div>
    <nav class="my-4 space-y-1">
      <NavBarLink
        v-for="link in activeLinks"
        v-bind="link"
        :key="link.dataTest"
        @close="emit('close')"
      />
    </nav>
  </div>
</template>
