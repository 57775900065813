<script lang="ts" setup name="OrganisationShipmentsCollapsibleVoyage">
import { computed, ref } from 'vue';

import { useOrganisationsStore } from '~/store';

import BaseButton from '~/components/BaseButton.vue';
import BaseCollapsible from '~/components/BaseCollapsible.vue';
import OrganisationShipmentsVoyageRow from '~/components/OrganisationShipmentsVoyageRow.vue';
import OrganisationShipmentsVoyageShipment from '~/components/OrganisationShipmentsVoyageShipment.vue';

import type {
  BaseTagContrastType,
  IShipment,
  IShipmentsFilterId,
  IVoyage,
} from '~/types';

const {
  organisationId,
  voyage,
  isExpanded = false,
  homeView = false,
  search = '',
  from,
} = defineProps<{
  organisationId: OrganisationId;
  voyage: IVoyage;
  isExpanded?: boolean;
  tagVariant: BaseTagContrastType;
  homeView?: boolean;
  search?: string | null;
  from?: IShipmentsFilterId;
}>();

const emit = defineEmits<{
  (e: 'selectVoyage', voyage: IVoyage): void;
  (e: 'selectShipment', shipment: IShipment): void;
}>();

const allShipmentsShown = ref(isExpanded);

const voyageShipments = computed(() => {
  if (!search) {
    return voyage.shipments;
  }

  if (!allShipmentsShown.value) {
    return voyage.shipments.filter((shipment) => shipment.visible);
  }

  return voyage.shipments.map((x) => ({
    ...x,
    marked: x.visible,
  }));
});

const allShipmentsVisible = computed(() =>
  voyage.shipments.every((shipment) => shipment.visible),
);

const toggleButtonText = computed(() =>
  allShipmentsShown.value ? 'see only search results' : 'see all shipments',
);

const toggleAllShipmentsShown = () => {
  allShipmentsShown.value = !allShipmentsShown.value;
};

const gridClasses = computed(() => {
  let classes =
    'shipment-scrollbar col-span-full grid max-h-[740px] overflow-x-auto overflow-y-auto overscroll-x-contain rounded-b bg-white';

  if (useOrganisationsStore().canReceiveShipmentsNotifications) {
    classes += ` grid-cols-[repeat(6,max-content),repeat(2,40px)] ${homeView ? 'xl:grid-cols-[minmax(27%,max-content),repeat(5,minmax(20%,max-content)),repeat(2,40px)]' : 'lg:grid-cols-[minmax(27%,max-content),repeat(5,minmax(20%,max-content)),repeat(2,50px)]'}`;
    return classes;
  }

  classes += ` grid-cols-[repeat(6,max-content),40px] ${homeView ? 'xl:grid-cols-[minmax(27%,max-content),repeat(5,minmax(20%,max-content)),40px]' : 'lg:grid-cols-[minmax(27%,max-content),repeat(5,minmax(20%,max-content)),50px]'}`;

  return classes;
});
</script>

<template>
  <div class="w-full rounded drop-shadow">
    <BaseCollapsible
      :open-by-default="isExpanded"
      class="grid w-full grid-cols-5 overflow-hidden"
      transitionClass="col-span-full grid grid-cols-subgrid"
      :class="
        homeView
          ? 'xl:grid-cols-[27%,repeat(3,20%),minmax(80px,1fr)]'
          : 'lg:grid-cols-[27%,repeat(3,20%),minmax(80px,1fr)]'
      "
    >
      <template #trigger="{ toggle, isOpen }">
        <OrganisationShipmentsVoyageRow
          :model-value="voyage.notify"
          v-bind="voyage"
          :tagVariant="tagVariant"
          :is-open="isOpen"
          :home-view="homeView"
          @click="toggle"
          @update:model-value="emit('selectVoyage', voyage)"
        />
      </template>
      <div data-test="voyage-shipments" :class="gridClasses">
        <OrganisationShipmentsVoyageShipment
          v-for="(shipment, index) in voyageShipments"
          :model-value="shipment.notify"
          v-bind="shipment"
          :key="shipment.id"
          :shipment-number="index + 1"
          :organisation-id="organisationId"
          :from="from"
          @update:model-value="emit('selectShipment', shipment)"
        />
        <div
          v-if="search && !allShipmentsVisible && !isExpanded"
          class="col-span-full bg-white py-1 pl-4"
        >
          <BaseButton inverted @click="toggleAllShipmentsShown">
            {{ toggleButtonText }}
          </BaseButton>
        </div>
      </div>
    </BaseCollapsible>
  </div>
</template>
