<script lang="ts" setup name="PublicSailingScheduleIndex">
import { ref } from 'vue';

import { useSnackbarStore } from '~/store';

import {
  getCategoricalColors,
  getCategoricalTagColors,
} from '~/features/useColors';
import { useLastUpdated } from '~/features/useLastSync';
import { useShowModal } from '~/features/useModalsProvider';
import { useOnResize } from '~/features/useOnResize';
import { useSailingScheduleTradeColoring } from '~/features/useSailingSchedule';
import {
  dateTypes,
  getPublicSailingScheduleFilters,
  getSailingScheduleDateType,
  setSailingScheduleDateType,
  useSailingScheduleFilters,
} from '~/features/useSailingScheduleFilters';
import { storage } from '~/features/useStorage';

import Service from '~/services/Service.js';

import BaseAlert from '~/components/BaseAlert.vue';
import BaseButton from '~/components/BaseButton.vue';
import BaseCardNoResults from '~/components/BaseCardNoResults.vue';
import BasePage from '~/components/BasePage.vue';
import PublicSailingScheduleTradeLaneCollapsible from '~/components/PublicSailingScheduleTradeLaneCollapsible.vue';
import PublicSailingScheduleTradeModal from '~/components/PublicSailingScheduleTradeModal.vue';
import SailingScheduleContent from '~/components/SailingScheduleContent.vue';
import SailingScheduleFilters from '~/components/SailingScheduleFilters.vue';
import SailingScheduleMobileFilters from '~/components/SailingScheduleMobileFilters.vue';
import SailingScheduleVoyageCollapsible from '~/components/SailingScheduleVoyageCollapsible.vue';
import TextToggle from '~/components/TextToggle.vue';

import type {
  ISailingScheduleFilters,
  SailingScheduleTradeLane,
  SailingScheduleVoyages,
} from '~/types';

const snackbar = useSnackbarStore();

const filters = ref<ISailingScheduleFilters>(
  storage.getPublicSailingScheduleQuery(),
);

const {
  query,
  tradelanesOptions,
  loadingPortsOptions,
  dischargePortsOptions,
  vesselsOptions,
  getTradelanes,

  activeFilters,
  clearAllFilters,
  clearActiveFilter,
} = useSailingScheduleFilters(filters);

const showModal = useShowModal();

const { isOnMobile } = useOnResize();

const { lastUpdated, setLastUpdated } = useLastUpdated();

const applyTradesSelection = async () => {
  snackbar.add({
    type: 'success',
    icon: 'ic:baseline-check-circle',
    text: 'Sailing Schedule Updated',
  });

  await getPublicSailingScheduleFilters();

  filters.value = storage.getPublicSailingScheduleQuery();
  query.tradelanes = getTradelanes();
};

const open = async () => {
  const result = await showModal(PublicSailingScheduleTradeModal, {});

  if (result.action === 'UPDATE') {
    applyTradesSelection();
  }
};

const { getTradeColor } = useSailingScheduleTradeColoring();

const tradelanes = ref<SailingScheduleTradeLane[]>([]);
const voyageTradelanes = ref<SailingScheduleVoyages[]>([]);
</script>

<template>
  <BasePage title="Sailing Schedule" :last-updated="lastUpdated">
    <BaseAlert type="info" class="col-span-full rounded drop-shadow">
      All dates, vessels, and port calls are subject to change without notice
    </BaseAlert>

    <SailingScheduleMobileFilters
      class="col-span-full block lg:hidden"
      v-model="query"
      add-trade
      :activeFilters="activeFilters"
      :tradelanes="tradelanesOptions"
      :discharge-ports="dischargePortsOptions"
      :loading-ports="loadingPortsOptions"
      :vessels="vesselsOptions"
      @clearFilters="clearAllFilters"
      @applyTradesSelection="applyTradesSelection"
    />

    <SailingScheduleFilters
      class="z-1 col-span-full hidden lg:block"
      v-model="query"
      :activeFilters="activeFilters"
      :tradelanes="tradelanesOptions"
      :discharge-ports="dischargePortsOptions"
      :loading-ports="loadingPortsOptions"
      :vessels="vesselsOptions"
      @clearFilter="clearActiveFilter"
      @clearFilters="clearAllFilters"
    >
      <BaseButton
        inverted
        variant="btn-secondary"
        label="add trade"
        class="-ml-4"
        icon="mdi:plus"
        @click="open"
      />
      <TextToggle
        :options="dateTypes"
        :modelValue="getSailingScheduleDateType()"
        @update:model-value="(value) => setSailingScheduleDateType(value)"
      />
    </SailingScheduleFilters>

    <div class="col-span-full">
      <SailingScheduleContent
        v-if="isOnMobile"
        paginated
        v-model:query="query"
        v-model:items="voyageTradelanes"
        :service="Service.publicSailingSchedule().indexMobile()"
        @setLastUpdated="setLastUpdated"
        @clearQuery="clearAllFilters"
      >
        <template #no-data>
          <BaseCardNoResults
            styled
            hideClear
            message="There are no voyages available for your selected trades at the moment."
          />
        </template>

        <template #no-results>
          <BaseCardNoResults
            v-if="activeFilters.length"
            styled
            message="There are no voyages available for this trade at the moment."
            @clear="clearAllFilters"
          />
        </template>

        <template #default="{ items }">
          <SailingScheduleVoyageCollapsible
            v-for="voyage in items"
            :key="voyage.tradelane"
            :voyage="voyage"
            v-bind="getTradeColor(voyage.tradelane)"
          />
        </template>
      </SailingScheduleContent>

      <SailingScheduleContent
        v-else
        v-model:query="query"
        v-model:items="tradelanes"
        :service="Service.publicSailingSchedule().index()"
        @setLastUpdated="setLastUpdated"
        @clearQuery="clearAllFilters"
      >
        <template #default="{ items }">
          <PublicSailingScheduleTradeLaneCollapsible
            v-for="(tradeLane, index) in items"
            :key="tradeLane.tradelane"
            :trade-lane="tradeLane"
            :tagVariant="getCategoricalTagColors(index)"
            :color="getCategoricalColors(index)"
          />
        </template>
      </SailingScheduleContent>
    </div>
  </BasePage>
</template>
