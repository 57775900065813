import { createPopper } from '@popperjs/core';

const PLACEMENT = {
  BOTTOM_CENTER: 'bottom',
  BOTTOM_END: 'bottom-end',
  BOTTOM_START: 'bottom-start',
  TOP_CENTER: 'top',
};

const showOtherTooltips = (visible: boolean) => {
  const tooltipsContainer = document.getElementById('tooltipTarget');

  if (tooltipsContainer) {
    tooltipsContainer.style.display = visible ? 'block' : 'none';
  }
};

const createElementFromHTML = (htmlString) => {
  const div = document.createElement('div');
  div.innerHTML = htmlString.trim();

  return div.firstChild;
};

const createTooltipEl = (text, size) =>
  createElementFromHTML(`
  <div id="popper" class="z-10 ${size} p-4 text-sm text-white bg-secondary-9 rounded-lg relative">
    <p>${text}</p>
    <div id="arrow" data-popper-arrow></div>
  </div>
  `);

const showPopper = (target, tooltip, placement) =>
  createPopper(target, tooltip, {
    placement,
    modifiers: [
      {
        name: 'hide',
      },
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
      {
        name: 'arrow', // Arrow modifier
        options: {
          element: '[data-popper-arrow]',
        },
      },
    ],
  });

export function getContent(value) {
  const type = typeof value;
  if (type === 'string') return value;

  if (value && type === 'object') return value.content;

  return false;
}

export function createTooltip(el, value, modifiers, customNodeId) {
  const size = modifiers.min ? 'w-max' : 'w-64';
  const newTooltip = createTooltipEl(getContent(value), size);
  const tooltip = {
    tooltipEl: newTooltip,
    refEl: el,
    popper: null,
    setContent(content) {
      if (!content) {
        this.dispose();
        return;
      }
      this.tooltipEl = createTooltipEl(content, size);
    },
    dispose() {
      this.refEl.removeEventListener('mouseenter', this.showTooltip);
      this.refEl.removeEventListener('mouseleave', this.hideTooltip);
      this.hideTooltip();
      this.tooltipEl = null;
    },
    showTooltip() {
      if (!this.tooltipEl) return;

      document.body.appendChild(this.tooltipEl);

      let placement = PLACEMENT.BOTTOM_END;

      if (modifiers.bottom) {
        placement = PLACEMENT.BOTTOM_CENTER;
      }

      if (modifiers.start) {
        placement = PLACEMENT.BOTTOM_START;
      }

      if (modifiers.top) {
        placement = PLACEMENT.TOP_CENTER;
      }

      const tooltipNode = customNodeId
        ? this.refEl.querySelector(`[data-test="${customNodeId}"]`)
        : this.refEl;

      this.popper = showPopper(tooltipNode, this.tooltipEl, placement);

      showOtherTooltips(false);
    },
    hideTooltip() {
      if (this.tooltipEl && this.tooltipEl.parentNode)
        this.tooltipEl.parentNode.removeChild(this.tooltipEl);

      this.popper?.destroy();

      showOtherTooltips(true);
    },
    show() {
      if (modifiers.click) {
        this.refEl.addEventListener('dblclick', this.showTooltip.bind(this));

        this.refEl.addEventListener('keydown', (event) => {
          if (event.key === 'Enter') {
            this.showTooltip();
          }
        });

        this.refEl.addEventListener('blur', this.hideTooltip.bind(this));
      } else {
        this.refEl.addEventListener('mouseenter', this.showTooltip.bind(this));
        this.refEl.addEventListener('mouseleave', this.hideTooltip.bind(this));
      }
    },
  };

  return tooltip;
}

export function destroyTooltip(el) {
  if (el._tooltip) {
    el._tooltip.dispose();
    el._tooltip = undefined;
  }
}

export function beforeMount(el, { value, modifiers, arg }) {
  const content = getContent(value);
  if (!content) {
    destroyTooltip(el);
    return;
  }

  let tooltip;

  if (el._tooltip) {
    tooltip = el._tooltip;
    tooltip.setContent(content);
  } else {
    tooltip = createTooltip(el, value, modifiers, arg);
  }

  el._tooltip = tooltip;
  tooltip.show();
}

export const Tooltip = {
  beforeMount,
  beforeUpdate(el) {
    destroyTooltip(el);
  },
  updated: beforeMount,
  unmounted(el) {
    destroyTooltip(el);
  },
};

export default Tooltip;
