<script lang="ts" setup name="LayoutAuthenticated">
import { ref } from 'vue';

import Icon from '~/components/Icon.vue';
import NavBarSidebar from '~/components/NavBarSidebar.vue';
import NotificationsMenu from '~/components/NotificationsMenu.vue';
import ProfileMenu from '~/components/ProfileMenu.vue';
import SupportButton from '~/components/SupportButton.vue';

const isOpen = ref(false);
</script>

<template>
  <div class="flex items-start">
    <NavBarSidebar :isOpen="isOpen" @setOpen="isOpen = $event" />

    <div
      class="flex-grow overflow-x-hidden"
      style="background: rgb(245, 245, 245)"
    >
      <div class="flex h-header flex-shrink-0 items-stretch bg-white shadow">
        <button
          data-test="navbar-toggle"
          class="mr-4 flex h-header w-header items-center justify-center hover:bg-charcoal-1 lg:hidden"
          @click.stop="isOpen = true"
        >
          <Icon icon="ic:baseline-menu" />
        </button>
        <div
          class="flex flex-grow"
          :class="{
            'pointer-events-none': isOpen,
          }"
        >
          <SupportButton :to="{ name: 'SupportIndex' }" />

          <NotificationsMenu class="md:ml-auto" />
          <ProfileMenu />
        </div>
      </div>

      <div
        class="w-full overflow-x-hidden"
        :class="{
          'pointer-events-none': isOpen,
        }"
      >
        <main class="w-full flex-grow">
          <slot />
        </main>
      </div>
    </div>
  </div>
</template>
