<script setup lang="ts" name="OrganisationHomeAllShipments">
import { onMounted } from 'vue';

import { SHIPMENT_TABS, STATES } from '~/constants';
import { useHomeStore, useSnackbarStore } from '~/store';

import { getCategoricalTagColors } from '~/features/useColors';
import { getOrgRoute } from '~/features/useRouteHelpers';
import {
  getSelectedVoyageShipments,
  getUpdatedSelectedVoyageNotification,
  getUpdateSelectedShipmentNotification,
  showShipmentsNotificationsSnackbar,
  updateShipmentsNotifications,
} from '~/features/useShipmentNotifications';

import BaseButton from '~/components/BaseButton.vue';
import ComponentErrorCard from '~/components/ComponentErrorCard.vue';
import OrganisationHomeAllShipmentsLayout from '~/components/OrganisationHomeAllShipmentsLayout.vue';
import OrganisationShipmentsCollapsibleVoyage from '~/components/OrganisationShipmentsCollapsibleVoyage.vue';
import OrganisationShipmentsVoyageSkeleton from '~/components/OrganisationShipmentsVoyageSkeleton.vue';
import ShipmentsNoDataCard from '~/components/ShipmentsNoDataCard.vue';

import type { IOrganisation, IShipment, IVoyage } from '~/types';

const { organisation } = defineProps<{
  organisation: IOrganisation;
}>();

const toShipmentsPage = {
  ...getOrgRoute('OrganisationShipmentsIndex', organisation.id),
  query: {
    status: SHIPMENT_TABS.ALL,
  },
};

const snackbar = useSnackbarStore();

const homeStore = useHomeStore();

onMounted(() => {
  homeStore.fetchShipments(organisation.id);
});

const selectVoyage = (selectedVoyage: IVoyage) => {
  const voyageShipments = getSelectedVoyageShipments(selectedVoyage);

  updateShipmentsNotifications(voyageShipments, organisation.id, () => {
    showShipmentsNotificationsSnackbar({
      notify: !selectedVoyage.notify,
      notifyFor: 'voyage',
      add: snackbar.add,
    });

    homeStore.voyages = getUpdatedSelectedVoyageNotification(
      homeStore.voyages,
      selectedVoyage,
    );
  });
};

const selectShipment = (selectedShipment: IShipment) => {
  updateShipmentsNotifications([selectedShipment], organisation.id, () => {
    showShipmentsNotificationsSnackbar({
      notify: !selectedShipment.notify,
      notifyFor: 'shipment',
      add: snackbar.add,
    });

    const updatedShipments = getUpdateSelectedShipmentNotification(
      homeStore.voyages,
      selectedShipment,
    );

    homeStore.voyages = updatedShipments;
  });
};
</script>

<template>
  <div>
    <ShipmentsNoDataCard
      v-if="homeStore.shipmentStateIs(STATES.NO_DATA)"
      :organisation="organisation"
    />

    <OrganisationHomeAllShipmentsLayout
      v-else-if="homeStore.shipmentStateIs(STATES.LOADING)"
    >
      <OrganisationShipmentsVoyageSkeleton
        data-testid="home-shipments-skeleton"
      />

      <template #footer>
        <div class="text-loading h-5 w-44" />
      </template>
    </OrganisationHomeAllShipmentsLayout>

    <ComponentErrorCard
      v-else-if="homeStore.shipmentStateIs(STATES.ERROR)"
      styled
      data-testid="home-shipments-error"
      @retry="homeStore.fetchShipments(organisation.id)"
    />

    <OrganisationHomeAllShipmentsLayout v-else>
      <OrganisationShipmentsCollapsibleVoyage
        data-testid="home-shipments"
        v-for="(voyage, index) in homeStore.voyages"
        home-view
        :key="voyage.id"
        :tagVariant="getCategoricalTagColors(index)"
        :voyage="voyage"
        :organisation-id="organisation.id"
        @selectVoyage="selectVoyage"
        @selectShipment="selectShipment"
      />

      <template #footer>
        <BaseButton inverted :to="toShipmentsPage">
          browse all shipments
        </BaseButton>
      </template>
    </OrganisationHomeAllShipmentsLayout>
  </div>
</template>
