<script setup lang="ts" name="OrganisationShipmentMilestone">
import { computed } from 'vue';

import { MILESTONE_STATUS } from '~/constants';

import BaseHeading from '~/components/BaseHeading.vue';
import Icon from '~/components/Icon.vue';

const {
  status,
  type = '',
  name = '',
  date = '',
  time = '',
  description = '',
  small = false,
  inBetween = false,
  first = false,
  last = false,
} = defineProps<{
  status: (typeof MILESTONE_STATUS)[keyof typeof MILESTONE_STATUS];
  type?: string;
  name?: string;
  date?: string;
  time?: string | null;
  description?: string;
  small?: boolean;
  inBetween?: boolean;
  first?: boolean;
  last?: boolean;
}>();

const inProgress = computed(() => {
  const statuses = [
    MILESTONE_STATUS.IN_PROGRESS,
    MILESTONE_STATUS.IN_PROGRESS_ACTIVE,
  ] as string[];

  return statuses.includes(status);
});

const done = computed(() => {
  const statuses = [MILESTONE_STATUS.DONE] as string[];

  return statuses.includes(status);
});

const upcoming = computed(() => {
  const statuses = [
    MILESTONE_STATUS.UPCOMING,
    MILESTONE_STATUS.UPCOMING_ACTIVE,
  ] as string[];

  return statuses.includes(status);
});

const topLineIsDashed = computed(() => {
  const statuses = [MILESTONE_STATUS.UPCOMING] as string[];

  return statuses.includes(status);
});

const bottomLineIsDashed = computed(() => {
  const statuses = [
    MILESTONE_STATUS.UPCOMING,
    MILESTONE_STATUS.UPCOMING_ACTIVE,
    MILESTONE_STATUS.IN_PROGRESS,
  ] as string[];

  return statuses.includes(status);
});

const size = computed(() => (small ? 'h-2 w-2' : 'h-5 w-5'));

const padding = computed(() => {
  if (inBetween) return '';
  return small ? 'py-2' : 'py-4';
});
</script>

<template>
  <div class="flex gap-4">
    <div class="flex w-5 flex-col items-center">
      <div
        class="grow border-l-2"
        :class="{
          'border-dashed': topLineIsDashed,
          'border-transparent': first,
          'border-secondary-3': !first,
        }"
      />

      <div
        v-if="done"
        class="grid place-items-center rounded-full bg-secondary-3"
        :class="size"
      >
        <Icon
          v-if="!small"
          icon="ic:baseline-check"
          class="text-white"
          size="xs"
        />
      </div>

      <div
        v-if="upcoming"
        class="rounded-full border-2 border-secondary-3 bg-white"
        :class="size"
      />

      <div
        v-if="inProgress"
        class="grid place-items-center rounded-full border-2 border-secondary-3 bg-white"
        :class="size"
      >
        <div v-if="!small" class="h-3 w-3 rounded-full bg-secondary-3" />
      </div>

      <div
        class="grow border-l-2"
        :class="{
          'border-dashed': bottomLineIsDashed,
          'border-transparent': last,
          'border-secondary-3': !last,
        }"
      />
    </div>

    <div class="flex items-center gap-4" :class="padding">
      <div v-if="type">
        <BaseHeading size="base">
          {{ name }}
        </BaseHeading>
        <p class="text-sm text-charcoal-6">
          {{ type }}
        </p>
      </div>
      <template v-else-if="inBetween">
        <div>
          <p class="text-sm text-transparent">&nbsp;</p>
          <div
            class="flex justify-between"
            :class="time ? 'w-[150px]' : 'w-32'"
          >
            <p v-title>{{ date }}</p>
            <p v-if="time">{{ time }}</p>
          </div>
          <p v-title class="text-sm text-charcoal-6">{{ name }}</p>
        </div>
        <p v-title :class="{ 'font-bold': inProgress }">
          {{ description }}
        </p>
      </template>
      <template v-else>
        <div class="flex justify-between" :class="time ? 'w-[150px]' : 'w-32'">
          <p v-title>{{ date }}</p>
          <p v-if="time">{{ time }}</p>
        </div>
        <p v-title>{{ name }}</p>
      </template>
    </div>
  </div>
</template>
