<script lang="ts" setup name="SailingScheduleTradeLanePort">
import { computed } from 'vue';

import BaseCircle from '~/components/BaseCircle.vue';

import type { IVesselVoyagePortVariant } from '~/types';

const {
  id,
  name,
  first = false,
  last = false,
  hover = false,
  visible = false,
  hasSelectedPorts = false,
  fullScreen = false,
  sorted = false,
  portVariant = 'secondary',
} = defineProps<{
  id: string;
  portVariant?: IVesselVoyagePortVariant;
  name: string;
  first?: boolean;
  last?: boolean;
  hover?: boolean;
  visible?: boolean;
  hasSelectedPorts?: boolean;
  fullScreen?: boolean;
  sorted?: boolean;
}>();

const emit = defineEmits<{
  (e: 'mouseover', id: string): void;
  (e: 'mouseout', id: string): void;
}>();

const isPortActive = computed(() => hasSelectedPorts && visible && fullScreen);

const shouldHaveBackground = computed(() => {
  if (isPortActive.value) return true;

  return hover;
});

const portVariantIs = (variant: IVesselVoyagePortVariant) =>
  portVariant === variant;
</script>

<template>
  <div
    class="relative h-14 overflow-hidden border-r border-charcoal-2"
    data-testid="sailing-schedule-trade-lane-port"
    :class="{
      'bg-charcoal-1': shouldHaveBackground && !sorted,
      'font-bold': isPortActive,
    }"
    @mouseover="emit('mouseover', id)"
    @mouseout="emit('mouseout', id)"
  >
    <div
      v-if="(hover && !first && !sorted) || isPortActive"
      class="absolute inset-x-0 top-0 h-[1px] bg-charcoal-2"
    />
    <div
      v-if="(hover && !sorted) || isPortActive"
      class="absolute inset-x-0 bottom-0 h-[1px] bg-charcoal-2"
    />

    <div class="flex gap-4 px-4">
      <div class="flex flex-col items-center">
        <div
          class="grow border-l"
          :class="{
            'border-transparent': first,
            'border-secondary-3': portVariantIs('secondary'),
            'border-charcoal-4': portVariantIs('charcoal'),
          }"
        />
        <BaseCircle
          size="md"
          border="solid"
          color="secondary"
          background="transparent"
          :disabled="portVariantIs('charcoal')"
        />
        <div
          class="grow border-l"
          :class="{
            'border-transparent': last,
            'border-secondary-3': portVariantIs('secondary'),
            'border-charcoal-4': portVariantIs('charcoal'),
          }"
        />
      </div>
      <p class="truncate py-4" :title="name">
        {{ name }}
      </p>
    </div>
  </div>
</template>
