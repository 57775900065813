<script lang="ts" setup name="CarbonOffsetingProcessModal">
import BaseButton from '~/components/BaseButton.vue';
import G2OBrandPattern from '~/components/G2OBrandPattern.vue';
import ModalCloseButton from '~/components/ModalCloseButton.vue';

import type { PromiseResolvePayload } from '~/types';

const { closeModal } = defineProps<{
  closeModal: (data: PromiseResolvePayload<'CLOSE'>) => void;
}>();

const onClose = () => {
  closeModal({ action: 'CLOSE' });
};
</script>
<template>
  <div class="w-[700px] rounded bg-white">
    <div class="flex">
      <G2OBrandPattern />
      <ModalCloseButton class="ml-09 mr-6 mt-6" @close="onClose" />
    </div>
    <div class="space-y-4 p-6 pt-16">
      <p class="text-2xl font-bold">About Carbon Offsetting Process</p>
      <ol class="ml-3 list-disc space-y-4 marker:text-charcoal-2">
        <li class="text-body-1">
          We have a trusted partnership with the company
          <a
            class="font-bold text-primary-8"
            rel="noopener"
            target="_blank"
            href="https://chooose.today/"
          >
            Chooose
          </a>
          to provide our customers with a safe and reliable carbon offsetting
          experience. All transactions are done directly through
          <a
            class="font-bold text-primary-8"
            rel="noopener"
            target="_blank"
            href="https://chooose.today/"
          >
            Chooose
          </a>
          , but we facilitate the process through the
          <span class="font-bold">MyG2</span> portal.
        </li>
        <li class="text-body-1">
          We calculate the CO2 emissions using the Sea Cargo Charterer for our
          vessels. We adjust for the distance covered by the vessel carrying the
          cargo and pro-rate to individual cargoes on the vessel.
        </li>
        <li class="text-subtitle-2 font-bold">
          Do you have any questions about the carbon offsetting process, our
          partnership with Chooose, or our decarbonisation efforts in general?
        </li>
      </ol>
      <p class="ml-3 text-subtitle-2">
        Please contact
        <a
          class="font-bold text-primary-8"
          href="mailto:sustainability@g2ocean.com"
          target="_blank"
        >
          sustainability@g2ocean.com
        </a>
      </p>
    </div>
    <div class="flex justify-end px-2 pb-2">
      <BaseButton inverted @click="onClose"> Dismiss </BaseButton>
    </div>
  </div>
</template>
