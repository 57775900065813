<script setup name="G2oTourStep">
import { computed, ref, watch } from 'vue';
import { createPopper } from '@popperjs/core';

import BaseButton from '~/components/BaseButton.vue';
import G2oTourTarget from '~/components/G2oTourTarget.vue';
import Icon from '~/components/Icon.vue';

const props = defineProps({
  currentStep: {
    type: Object,
    required: true,
  },
  stepLabel: {
    type: Number,
    default: 0,
  },
  index: {
    type: Number,
    default: 0,
  },
  totalSteps: {
    type: Number,
    default: 0,
  },
  id: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: 'title',
  },
  description: {
    type: String,
    default: 'description',
  },
  offset: {
    type: Array,
    default: () => [0, 20],
  },
  selector: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['close', 'next', 'prev']);

const TOTAL_TRIES = 30;
const TICK_TIMEOUT = 10;
let createStepTicks = 0;
let updateStepTicks = 0;

const targetRef = ref(null);
const targetPopper = ref(null);
const tipRef = ref(null);
const tipPopper = ref(null);
const reference = ref(null);

const stepIsActive = computed(() => props.id === props.currentStep.id);

const stepIsHidden = computed(() => {
  if (!props.selector || !reference.value) return true;

  return props.currentStep.index > props.index;
});

const createTargetPopper = () => {
  if (targetPopper.value) {
    targetPopper.value.update();
    return;
  }

  targetPopper.value = createPopper(reference.value, targetRef.value.$el, {
    placement: 'right',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: props.offset,
        },
      },
    ],
  });
};

const createTipPopper = () => {
  if (tipPopper.value) {
    tipPopper.value.update();
    return;
  }

  tipPopper.value = createPopper(targetRef.value.$el, tipRef.value, {
    placement: 'right-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [-10, 10],
        },
      },
    ],
  });
};

const createStep = () => {
  reference.value = document.querySelector(`[data-test="${props.selector}"]`);
  if (!reference.value || !targetRef.value) {
    targetPopper.value?.destroy();
    targetPopper.value = null;

    tipPopper.value?.destroy();
    tipPopper.value = null;
    setTimeout(() => {
      if (createStepTicks < TOTAL_TRIES) {
        createStepTicks += 1;
        createStep();
      }
    }, TICK_TIMEOUT);
    return;
  }
  createTargetPopper();
  createTipPopper();
};

const updateStep = () => {
  tipPopper.value?.update();
  setTimeout(() => {
    if (updateStepTicks < TOTAL_TRIES + 1) {
      updateStepTicks += 1;
      updateStep();
    }
  }, TICK_TIMEOUT);
};

watch(
  () => props.currentStep,
  () => {
    createStepTicks = 0;
    createStep();
  },
  {
    immediate: true,
  },
);

watch(stepIsActive, (isActive) => {
  if (!isActive) return;

  updateStepTicks = 0;
  updateStep();
});
</script>

<template>
  <div>
    <G2oTourTarget
      ref="targetRef"
      :class="{ hidden: stepIsHidden, 'opacity-25': !stepIsActive }"
      :animated="stepIsActive"
    />
    <div ref="tipRef">
      <div
        v-if="stepIsActive"
        class="flex h-72 w-80 flex-col rounded-lg bg-secondary-3 p-4 text-charcoal-8 shadow-md"
      >
        <button
          type="button"
          data-test="g2o-tour-step-close"
          class="absolute right-0 top-0 m-4"
          @click="emit('close')"
        >
          <Icon icon="ic:round-close" />
        </button>
        <h2 class="text-xs uppercase">
          Tip {{ stepLabel }} out of {{ totalSteps }}
        </h2>
        <p class="mt-5">
          <strong>{{ title }}</strong>
          {{ description }}
        </p>
        <div class="mt-auto flex items-center justify-between">
          <BaseButton inverted @click="emit('prev')">Previous</BaseButton>
          <BaseButton variant="btn-secondary" @click="emit('next')">
            Next
            <Icon icon="mdi:arrow-right-thin" class="ml-4" />
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>
