<script lang="ts" setup name="ProfileMenuButton">
import Icon from '~/components/Icon.vue';

const {
  firstName,
  lastName,
  isOpen = false,
} = defineProps<{
  firstName?: string;
  lastName?: string;
  isOpen?: boolean;
}>();

const emit = defineEmits<{
  (e: 'click'): void;
}>();
</script>

<template>
  <button
    class="flex h-header items-center justify-center space-x-2 border bg-white px-6 py-1 lg:w-auto"
    :class="{
      'border-charcoal-3 bg-charcoal-2': isOpen,
      'border-transparent bg-white text-primary-8 hover:bg-charcoal-1': !isOpen,
    }"
    data-test="profile-dropdown-button"
    @click="emit('click')"
  >
    <Icon
      icon="ic:outline-account-circle"
      data-test="profile-avatar"
      size="3xl"
    />

    <div class="hidden items-center space-x-2 text-secondary-9 lg:flex">
      <p class="font-bold">{{ firstName }} {{ lastName }}</p>
      <Icon
        icon="mdi:chevron-down"
        class="transform"
        :class="{
          'rotate-180': isOpen,
        }"
      />
    </div>
  </button>
</template>
