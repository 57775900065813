<script setup lang="ts" name="SailingScheduleDownload">
import { ref } from 'vue';

import { setDownloadAnchor } from '~/features/fileUtilities';
import { getSailingScheduleDateType } from '~/features/useSailingScheduleFilters';

import Service from '~/services/Service.js';

import BaseButton from '~/components/BaseButton.vue';

import type { IFileDownloadResponse, ITradeLaneVessel } from '~/types';

const { organisationId, tradeLane, vessels } = defineProps<{
  organisationId?: string;
  tradeLane: string;
  vessels: ITradeLaneVessel[];
}>();

const isLoading = ref(false);

const onDownload = async () => {
  await new Promise((resolve, reject) => {
    Service.organisation(organisationId)
      .sailingScheduleV2()
      .voyages(tradeLane)
      .download()
      .data({
        voyages: vessels.map((x) => ({
          id: x.id,
          visible: x.visible,
        })),
        dateType: getSailingScheduleDateType(),
      })
      .onStart(() => {
        isLoading.value = true;
      })
      .onSuccess((file: IFileDownloadResponse) => {
        setDownloadAnchor(file);
        resolve(true);
      })
      .onError((error: unknown) => {
        reject(error);
      })
      .onFinish(() => {
        isLoading.value = false;
      })
      .execute();
  });
};
</script>

<template>
  <BaseButton
    inverted
    icon="ic:baseline-insert-drive-file"
    :loading="isLoading"
    @click="onDownload"
  >
    Download
  </BaseButton>
</template>
