<script setup lang="ts" name="PublicSharedShipment">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import { usePortContacts } from '~/features/usePortContacts';
import { useService } from '~/features/useService';
import {
  type ShipmentMilestone,
  useShipmentMilestones,
} from '~/features/useShipmentMilestones';
import { getShipmentInfo } from '~/features/useShipmentPreview';

import Service from '~/services/Service.js';

import G2oPublicLayout from '~/components/G2oPublicLayout.vue';
import SharedShipmentPreview from '~/components/SharedShipmentPreview.vue';

import type {
  IBaseItem,
  IOrganisationShipmentTrackingResponse,
  ISharedContactCard,
  ISharedPreviewResponse,
  ISharedShipmentInfo,
  ISharedShipmentOrganisation,
  IVesselVoyageData,
} from '~/types';

const props = defineProps<{
  id: string;
}>();

const shipmentInformation = ref<ISharedShipmentInfo>();
const portsAndDates = ref<ShipmentMilestone[]>([]);
const portContacts = ref<ISharedContactCard>();
const vesselVoyageData = ref<IVesselVoyageData>();
const organisation = ref<ISharedShipmentOrganisation>();

const router = useRouter();

const serviceState = useService(
  Service.sharedShipment().index(props.id),
  ({ data }: ISharedPreviewResponse) => {
    shipmentInformation.value = data.shipmentInformation;
    portsAndDates.value = useShipmentMilestones(data.portsAndDates);
    organisation.value = data.organisation;

    const { portAgents } = data;

    portContacts.value = usePortContacts(
      portAgents,
      'Port Agents',
      'Port Agent',
      'port_agents',
    );

    return true;
  },
  {
    onErrorNotFoundRedirection: () => {
      router.push('/404');
    },
  },
);

const trackingService = useService(
  Service.sharedShipment().tracking(props.id),
  ({ data }: IOrganisationShipmentTrackingResponse) => {
    vesselVoyageData.value = data;

    return true;
  },
);

const shipmentInfo = computed<IBaseItem[]>(() =>
  getShipmentInfo(shipmentInformation.value, organisation.value),
);
</script>

<template>
  <G2oPublicLayout>
    <div class="mb-20 mt-24 flex h-full w-full justify-center">
      <SharedShipmentPreview
        :portContacts="portContacts"
        :serviceState="serviceState"
        :shipmentInfo="shipmentInfo"
        :portsAndDates="portsAndDates"
        portsAndDatesTooltip="Timestamps dates are displayed in 24-hour format and show the local port time."
        :vesselVoyageData="vesselVoyageData"
        :trackingService="trackingService"
      />
    </div>
  </G2oPublicLayout>
</template>
