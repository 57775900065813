<script setup lang="ts" name="PublicUserCargoTracker">
import { reactive, ref, watch } from 'vue';

import { usePortContacts } from '~/features/usePortContacts';
import { useService } from '~/features/useService';
import {
  type ShipmentMilestone,
  useShipmentMilestones,
} from '~/features/useShipmentMilestones';

import Service from '~/services/Service';

import BaseCardNoResults from '~/components/BaseCardNoResults.vue';
import BasePage from '~/components/BasePage.vue';
import CargoTrackerDetails from '~/components/CargoTrackerDetails.vue';
import PublicUserCargoTrackerNoData from '~/components/PublicUserCargoTrackerNoData.vue';
import SearchField from '~/components/SearchField.vue';
import ServiceCard from '~/components/ServiceCard.vue';

import type {
  IBasePage,
  IBillOfLadingInfo,
  IBillOfLadingResponse,
  ISharedContactCard,
  IVesselVoyageData,
} from '~/types';

const page: IBasePage = {
  title: 'Cargo Tracker',
  subTitle: 'Find your cargo details by entering bill of lading number.',
};

const query = reactive({
  search: '',
});

const bolInformation = ref<IBillOfLadingInfo>();
const portsAndDates = ref<ShipmentMilestone[]>([]);
const portContacts = ref<ISharedContactCard>();
const vesselVoyageData = ref<IVesselVoyageData>();

const serviceState = useService(
  Service.publicUserCargo().index(),
  ({ data }: IBillOfLadingResponse) => {
    bolInformation.value = data.bolInformation;

    vesselVoyageData.value = data.tracking;

    portsAndDates.value = useShipmentMilestones(data.portsAndDates);

    const { portAgents } = data;

    portContacts.value = usePortContacts(
      portAgents,
      'Port Agents',
      'Port Agent',
      'port_agents',
    );

    return true;
  },
  {
    cancelOnNewRequest: true,
    debounceTime: 500,
    query,
    preventFetch: true,
    state: 'NO_DATA',
    preventWatch: () => !query.search,
    getQuery: () => ({
      bol_name: query.search,
    }),
  },
);

watch(
  () => query.search,
  (val) => {
    if (!val) {
      serviceState.setStateNoData();
    }
  },
);

const onClearSearch = () => {
  query.search = '';
};
</script>

<template>
  <BasePage v-bind="page">
    <SearchField
      class="col-span-full shadow"
      placeholder="Search by Bill of Lading Number"
      v-model="query.search"
      focus
    />

    <ServiceCard
      v-bind="serviceState"
      styled
      @clear="onClearSearch"
      not-available-title="Shipment is No Longer Available"
      not-available-message="Shipment has been completed."
    >
      <template #validation-error>
        <BaseCardNoResults
          class="col-span-full"
          styled
          @clear="onClearSearch"
        />
      </template>

      <template #no-data>
        <PublicUserCargoTrackerNoData
          class="col-span-full"
          :serviceState="serviceState"
        />
      </template>

      <div class="col-span-full space-y-2">
        <CargoTrackerDetails
          portsAndDatesTooltip="Timestamps dates are displayed in 24-hour format and show the local port time."
          :serviceState="serviceState"
          :bolInformation="bolInformation"
          :portsAndDates="portsAndDates"
          :portContacts="portContacts"
          :vesselVoyageData="vesselVoyageData"
        />
      </div>
    </ServiceCard>
  </BasePage>
</template>
