<script setup lang="ts" name="OrganisationHomeTheQuickLinks">
import { computed } from 'vue';

import { ORG_FEATURES } from '~/constants';
import { useDevFeaturesStore } from '~/store';

import { sendInviteUserEvent } from '~/features/useAnalytics';
import { featureIsEnabled } from '~/features/useFeatures';
import { useShowModal } from '~/features/useModalsProvider';
import { hasOneOfPermissions } from '~/features/usePermissions';
import { getOrgRoute } from '~/features/useRouteHelpers';

import BaseCard from '~/components/BaseCard.vue';
import BaseCardLink from '~/components/BaseCardLink.vue';
import InviteUserModal from '~/components/InviteUserModal.vue';

import type { IOrganisation, IOrgFeature, IQuickLink } from '~/types';

const { organisation, oceanLinkVisible, loading } = defineProps<{
  organisation: IOrganisation;
  oceanLinkVisible?: boolean;
  loading: boolean;
}>();

const showModal = useShowModal();

const checkFeature = (feature: IOrgFeature) =>
  featureIsEnabled(feature, organisation);

const onOpenUserModal = () => {
  showModal(InviteUserModal, {
    organisationId: organisation.id,
  });

  sendInviteUserEvent('Home');
};

const items = computed<IQuickLink[]>(() => {
  const hasPermission = hasOneOfPermissions(
    ['invitation.create.any', 'invitation.create.own'],
    organisation,
  );

  const allItems: IQuickLink[] = [
    {
      title: 'Sailing Schedule',
      label: 'Browse upcoming voyages',
      to: getOrgRoute('SailingScheduleIndex', organisation.id),
      enabled: checkFeature(ORG_FEATURES.SAILING_SCHEDULE),
    },
    {
      title: 'Cargo Declaration',
      label: 'See declaration',
      to: getOrgRoute('OrganisationBookingsIndex', organisation.id),
      enabled:
        checkFeature(ORG_FEATURES.BOOKINGS) &&
        useDevFeaturesStore().CARGO_NOMINATION,
    },
    {
      title: 'My Organisation',
      label: 'Invite new user',
      click: onOpenUserModal,
      enabled: hasPermission,
    },
    {
      title: 'My Organisation',
      label: 'See Organisation',
      to: getOrgRoute('OrganisationSettingsIndex', organisation.id),
      enabled: !hasPermission,
    },
    {
      title: 'Ocean Docs',
      label: 'Go to OceanDocs',
      href: 'https://oceandocs.systems/',
      target: '_blank',
      enabled: oceanLinkVisible,
    },
  ];

  return allItems.filter((item) => item.enabled !== false);
});
</script>

<template>
  <BaseCard title="Quick links">
    <div class="-mt-4 divide-y divide-charcoal-2">
      <div v-for="(item, index) in items" :key="index" class="space-y-2 py-4">
        <p class="text-sm" :class="{ 'text-loading w-32': loading }">
          {{ item.title }}
        </p>
        <BaseCardLink
          :to="item.to"
          :href="item.href"
          :target="item.target"
          :loading="loading"
          :disabled="loading"
          @click="item.click"
        >
          {{ item.label }}
        </BaseCardLink>
      </div>
    </div>
  </BaseCard>
</template>
