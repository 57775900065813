<script lang="ts" setup name="SailingScheduleTradeLaneVoyagesLoading">
import BaseCircle from '~/components/BaseCircle.vue';

const PORTS_LENGTH = 6;
const PORT_DATES_LENGTH = 5;
</script>

<template>
  <div class="rounded-b-md">
    <div class="flex w-full flex-col">
      <div
        class="animate grid h-16 animate-pulse grid-cols-[224px_minmax(0,_1fr)]"
      >
        <div class="right-shadow border-r border-charcoal-2 bg-charcoal-2" />
        <div class="bg-charcoal-2" />
      </div>

      <div class="w-full rounded-b bg-white">
        <div
          v-for="i in PORTS_LENGTH"
          :key="i"
          class="grid h-14 w-full grid-cols-[224px_minmax(0,_1fr)] border-r border-charcoal-2"
        >
          <div class="right-shadow animate flex animate-pulse gap-4 px-4">
            <div class="flex flex-col items-center">
              <div
                class="grow border-l"
                :class="i === 1 ? 'border-transparent' : 'border-charcoal-2'"
              />
              <BaseCircle
                disabled
                border="solid"
                size="sm"
                background="transparent"
              />
              <div
                class="grow border-l"
                :class="
                  i === PORTS_LENGTH
                    ? 'border-transparent'
                    : 'border-charcoal-2'
                "
              />
            </div>
            <div class="flex items-center">
              <div class="h-3 w-36 rounded-lg bg-charcoal-2" />
            </div>
          </div>
          <div class="flex w-full rounded-b-lg bg-white">
            <div
              v-for="x in PORT_DATES_LENGTH"
              :key="x"
              class="animate flex w-full animate-pulse items-center justify-evenly"
            >
              <div class="h-3 w-24 rounded-lg bg-charcoal-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
