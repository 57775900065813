<script setup name="CookiePolicy">
import { useAnalyticsStore } from '~/store';

import BaseButton from '~/components/BaseButton.vue';
import CookiePolicyContact from '~/components/CookiePolicyContact.vue';
import CookiePolicyHeading from '~/components/CookiePolicyHeading.vue';
import CookiePolicyParagraph from '~/components/CookiePolicyParagraph.vue';

const showCookieLaw = () => useAnalyticsStore().showCookieLaw();

const accountCollapsibles = [
  {
    title: 'Account related cookies',
    description:
      'If you have an account with us we will use cookies for the management of the signup process and general administration. These cookies will be deleted when you log out, however, they remain to remember your site preferences when logged out if you have selected “Remember me” on the login site.',
  },
  {
    title: 'Login related cookies',
    description:
      'We use cookies when you are logged in so that we can remember this fact. This prevents you from having to log in every single time you visit our page. These cookies are removed or cleared when you log out to ensure that you can only access restricted features and areas when logged in.',
  },
  {
    title: 'Surveys related cookies',
    description:
      'From time to time we offer user surveys and questionnaires to provide you with interesting insights, helpful tools, or to understand our user base more accurately. These surveys may use cookies to remember if you have already taken part in a survey or to provide you with accurate results after you change pages. Please note that all survey and questionnaires are anonymous.',
  },
  {
    title: 'Forms related cookies',
    description:
      'When you submit data through a form such as booking request, new user form or comment sections, cookies may be set to remember your user details for future correspondence.',
  },
  {
    title: 'Site preferences cookies',
    description:
      'In order to provide you with a good experience on this site we provide the functionality to set your preferences for how this site runs when you use it. In order to remember your preferences we need to set cookies so that your preferences are called whenever you interact with a page.',
  },
];

const thirdPartyCollapsibles = [
  {
    id: 1,
    title: 'Google Analytics',
    description:
      'This site uses Google Analytics for helping us to understand how you use the site and ways that we can improve your experience. These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to improve our content. The information collected by Google Analytics is transmitted to and stored with Google. Google may transmit the information collected by Google Analytics to the third parties as required by the law or when those third parties process the information in the name of Google. <br/> For more information on Google Analytics cookies, see the official Google Analytics page.',
  },
];

const whyCookiesCounted = [
  {
    description:
      'To ensure efficient and safe functioning of the site. We use cookies to enable and support our security features, and to detect malicious activity on your site.',
  },
  {
    description:
      'To understand and improve the use of features and services on the site, including when you access the site from other websites or devices.',
  },
  {
    description:
      'To recognise returning visitors, and to help us show you the right information and personalise your experience.',
  },
];
</script>

<template>
  <div class="space-y-6">
    <slot name="heading">
      <CookiePolicyHeading />
    </slot>
    <CookiePolicyParagraph>
      <template #title>
        <h1 class="text-2xl font-bold">What Are Cookies</h1>
      </template>
      MyG2 uses cookies, as is common practice with professional websites.
      Cookies are small files that are placed onto your device that enables our
      site features and functionality. This page describes what information they
      gather, how we use it and why we need to store these cookies. We will also
      share how you can prevent these cookies from being stored.
    </CookiePolicyParagraph>
    <CookiePolicyParagraph :counted="whyCookiesCounted">
      <template #title>
        <h1 class="text-2xl font-bold">Why We Use Cookies</h1>
      </template>
      We use cookies for the following main purposes:
    </CookiePolicyParagraph>
    <CookiePolicyParagraph
      title="User and Account Related Cookies"
      :collapsibles="accountCollapsibles"
    >
      User and Account Related Cookies:
    </CookiePolicyParagraph>
    <CookiePolicyParagraph
      title="Third Party Cookies"
      :collapsibles="thirdPartyCollapsibles"
    >
      We use cookies provided by trusted third parties to understand the use of
      the application. The following section details which third party cookies
      you might encounter through this site.
    </CookiePolicyParagraph>
    <CookiePolicyParagraph title="Change Cookie Setting">
      You can prevent the setting of third party cookies by disabling or denying
      cookies. Be aware that disabling cookies may affect the functionality of
      this website. It is recommended that you leave on all cookies, since they
      are used to provide an improved service to users of the application.
      <template #append>
        <BaseButton inverted @click="showCookieLaw">
          manage settings
        </BaseButton>
      </template>
    </CookiePolicyParagraph>
    <CookiePolicyContact />
  </div>
</template>
