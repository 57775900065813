<script setup name="BaseHeading">
import { computed } from 'vue';

const props = defineProps({
  size: {
    type: String,
    default: 'xl',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const SIZE = {
  sm: 'text-sm',
  base: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl',
};

const headingSize = computed(() => SIZE[props.size]);
</script>

<template>
  <h2
    class="font-body font-bold"
    :class="[
      {
        'text-secondary-9': !isLoading,
        'animate w-40 animate-pulse rounded-full bg-charcoal-2 text-charcoal-2':
          isLoading,
      },
      headingSize,
    ]"
  >
    <slot />
  </h2>
</template>
