<script lang="ts" setup name="G2oTourSwitchOrganisation">
import { computed } from 'vue';

import BaseButton from '~/components/BaseButton.vue';
import SimpleCard from '~/components/Cards/SimpleCard.vue';
import Icon from '~/components/Icon.vue';

const { currentStep, id } = defineProps<{
  currentStep: {
    id: string;
  };
  id: string;
}>();

const emit = defineEmits<{
  (e: 'prev'): void;
  (e: 'next'): void;
}>();

const stepIsActive = computed(() => id === currentStep.id);
</script>

<template>
  <SimpleCard v-if="stepIsActive" class="max-w-sm space-y-4 bg-white p-6">
    <h2 class="subtitle-1 font-title text-lg font-bold">
      Switch to Customer Organisation
    </h2>
    <p>We would like to proceed by switching to a customer Organisation.</p>
    <div class="flex items-center justify-end space-x-4">
      <BaseButton variant="btn-secondary" @click="emit('prev')">
        Back
      </BaseButton>
      <BaseButton @click="emit('next')">
        Next
        <Icon icon="mdi:arrow-right-thin" class="ml-4" />
      </BaseButton>
    </div>
  </SimpleCard>
</template>
