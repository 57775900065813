import type { UserModule } from '~/types';

import dragScroll from '~/directives/dragScroll';
import focusTrap from '~/directives/focusTrap';
import title from '~/directives/title';
import tooltip from '~/directives/tooltip';

export const install: UserModule = ({ app }) => {
  app.directive('tooltip', tooltip);
  app.directive('title', title);
  app.directive('focusTrap', focusTrap);
  app.directive('drag-scroll', dragScroll);
};
