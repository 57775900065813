<script setup lang="ts" name="ShipmentsNoDataCard">
import { computed } from 'vue';

import { ORG_FEATURES } from '~/constants';
import { useDevFeaturesStore } from '~/store';

import { featureIsEnabled } from '~/features/useFeatures';
import { useShowModal } from '~/features/useModalsProvider';
import { getOrgRoute } from '~/features/useRouteHelpers';

import BaseButton from '~/components/BaseButton.vue';
import BaseCardNoData from '~/components/BaseCardNoData.vue';
import ModalConfirmInfo from '~/components/ModalConfirmInfo.vue';

import type { IOrganisation } from '~/types';

const { organisation } = defineProps<{
  organisation: IOrganisation;
}>();

const showModal = useShowModal();

const bookingsAreEnabled = computed(
  () =>
    featureIsEnabled(ORG_FEATURES.BOOKINGS, organisation) &&
    useDevFeaturesStore().CARGO_NOMINATION,
);

const firmBookingRoute = computed(() =>
  getOrgRoute('OrganisationBookingsIndex', organisation.id),
);

const onClickWithNoContract = () => {
  showModal(ModalConfirmInfo, {
    title: 'You don’t have any active contract!',
    message:
      'We cannot find any active contracts with G2 Ocean. Please contact your Chartering Manager at G2 Ocean.',
  });
};
</script>

<template>
  <BaseCardNoData
    title="You Have No Shipments..."
    illustration="no-data"
    :message="bookingsAreEnabled ? 'Start by making a new booking!' : null"
    styled
  >
    <BaseButton
      v-if="bookingsAreEnabled"
      icon="mdi:plus"
      class="mt-4"
      inverted
      :to="firmBookingRoute"
      @click="onClickWithNoContract"
    >
      new booking
    </BaseButton>
  </BaseCardNoData>
</template>
