<script setup lang="ts" name="VesselsIndex">
import { useVModel } from '@vueuse/core';

import type { useService } from '~/features/useService.js';

import BasePage from '~/components/BasePage.vue';
import BasePaginationTable from '~/components/BasePaginationTable.vue';
import SearchField from '~/components/SearchField.vue';
import ServiceCard from '~/components/ServiceCard.vue';
import VesselsLayout from '~/components/VesselsLayout.vue';

import type { ITableHeader, IVesselsShow } from '~/types';

const props = defineProps<{
  modelValue: string | null;
  serviceState: ReturnType<typeof useService>;
  vessels: IVesselsShow[];
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void;
}>();

const inputValue = useVModel(props, 'modelValue', emit);

const headers = [
  {
    label: 'Vessel Name',
    key: 'name',
  },
  {
    label: 'IMO',
    key: 'imo',
    alignRight: true,
  },
  {
    label: 'DWT',
    key: 'dwt',
    type: 'number_unit',
    unit: 'MT',
  },
  {
    label: 'GRT',
    key: 'grt',
    type: 'number',
  },
  {
    label: 'NRT',
    key: 'nrt',
    type: 'number',
  },
  {
    label: 'LOA',
    key: 'loa',
    type: 'number_unit',
    decimal: 1,
    unit: 'm',
  },
  {
    label: 'Bale Capacity',
    key: 'bale',
    type: 'number_unit',
    unit: 'cbm',
  },
  {
    label: 'Crane Capacity',
    key: 'craneCapacity',
    type: 'number',
  },
  {
    label: 'Draft Summer',
    key: 'draftSummer',
    type: 'number_unit',
    decimal: 2,
    unit: 'm',
  },
] satisfies ITableHeader[];

const onClearSearch = () => {
  inputValue.value = '';
};
</script>

<template>
  <BasePage title="Vessels">
    <VesselsLayout>
      <template #search>
        <SearchField
          v-model="inputValue"
          placeholder="Search by the Vessel Name"
          focus
        />
      </template>

      <ServiceCard
        v-bind="serviceState"
        no-results-message="Please amend the search criteria or clear the search field to retrieve all available vessels."
        @clear="onClearSearch"
      >
        <BasePaginationTable
          id="Vessels Table"
          :headers="headers"
          :table-rows="vessels"
          is-responsive
          clickable-rows
          :loading="serviceState.stateIsLoading"
        />
      </ServiceCard>
    </VesselsLayout>
  </BasePage>
</template>
