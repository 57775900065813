import { MILESTONE_STATUS, STATUS_TO_MILESTONE_STATUS } from '~/constants';

import type {
  IPortsAndDates,
  IPortsAndDatesEvents,
  IPortsAndDatesInBetween,
} from '~/types';

import { getDateYear, getTimeFromDate } from './useDates';

type MilestoneStatus = (typeof MILESTONE_STATUS)[keyof typeof MILESTONE_STATUS];

export type ShipmentMilestone = ShipmentMilestoneRaw & {
  first: boolean;
  last: boolean;
};

type ShipmentMilestoneRaw = {
  id: string;
  status: MilestoneStatus;
  type?: string;
  name?: string;
  date?: string;
  time?: string | null;
  description?: string;
  small?: boolean;
  inBetween?: boolean;
  happened?: boolean;
  first?: boolean;
  last?: boolean;
};

const withMeta = (
  port: ShipmentMilestoneRaw,
  index: number,
  allPorts: ShipmentMilestoneRaw[],
): ShipmentMilestone => {
  const prev = allPorts[index - 1];
  const first = index === 0;
  const last = index === allPorts.length - 1;
  let { status } = port;

  if (
    prev &&
    prev.status === MILESTONE_STATUS.DONE &&
    status === MILESTONE_STATUS.UPCOMING
  ) {
    status = MILESTONE_STATUS.UPCOMING_ACTIVE;
  }

  return {
    ...port,
    status,
    first,
    last,
  };
};

const getEventName = (key: string, happened: boolean) => {
  if (key === 'arrivalDate') return happened ? 'Arrived' : 'Est. Arrival';

  if (key === 'berthedDate') return happened ? 'Berthed' : 'Est. Berth';

  if (key === 'departureDate') return happened ? 'Departed' : 'Est. Departure';

  return key;
};

const spreadPortAndEvents = (
  acc: ShipmentMilestoneRaw[],
  index: number,
  portData: IPortsAndDatesEvents,
): ShipmentMilestoneRaw[] => {
  const port = {
    id: `${portData.name}-${index}`,
    name: portData.name,
    type: portData.type === 'L' ? 'Load Port' : 'Discharge Port',
    status: MILESTONE_STATUS.UPCOMING as MilestoneStatus,
  };

  const ports: ShipmentMilestoneRaw[] = [];
  const events = ['arrivalDate', 'berthedDate', 'departureDate'];

  for (const key of events) {
    const event = portData[key];
    if (!event) continue;
    ports.push({
      id: `${port.name}-${key}`,
      date: getDateYear(event.date),
      time: getTimeFromDate(event.date),
      name: getEventName(key, event.happened),
      happened: event.happened,
      status: event.happened
        ? MILESTONE_STATUS.DONE
        : MILESTONE_STATUS.UPCOMING,
      small: true,
    });
  }

  if (ports.every((event) => event.happened)) {
    port.status = MILESTONE_STATUS.DONE;
  } else {
    const [arrivalEvent] = ports;
    port.status = arrivalEvent.happened
      ? MILESTONE_STATUS.IN_PROGRESS_ACTIVE
      : MILESTONE_STATUS.UPCOMING;
  }

  return [...acc, port, ...ports];
};

const getInBetweenPort = (
  acc: ShipmentMilestoneRaw[],
  index: number,
  port: IPortsAndDatesInBetween,
): ShipmentMilestoneRaw[] => {
  return [
    ...acc,
    {
      name: port.name,
      date: getDateYear(port.date),
      time: getTimeFromDate(port.date),
      inBetween: true,
      id: `${port.name}-${index}`,
      status: STATUS_TO_MILESTONE_STATUS[port.status],
      description: port.status === 'est_arrival' ? 'Est. Arrival' : 'Arrived',
    },
  ];
};

export const useShipmentMilestones = (
  ports: IPortsAndDates[],
  showInBetween = false,
): ShipmentMilestone[] =>
  ports
    .reduce(
      (acc, port, index) =>
        'type' in port
          ? spreadPortAndEvents(acc, index, port)
          : showInBetween
            ? getInBetweenPort(acc, index, port)
            : acc,
      [] as ShipmentMilestoneRaw[],
    )
    .map(withMeta);
