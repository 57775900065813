<script setup lang="ts" name="NotificationsMenuButton">
import { computed } from 'vue';

import Icon from '~/components/Icon.vue';

const { isOpen = false, totalUnread = 0 } = defineProps<{
  isOpen?: boolean;
  totalUnread?: number;
}>();

const notificationsCount = computed<number | string | null>(() =>
  totalUnread ? (totalUnread > 99 ? '99+' : totalUnread) : null,
);

const hasUnread = computed(() => totalUnread > 0);
</script>

<template>
  <button
    class="relative flex h-header w-header items-center justify-center border"
    :class="{
      'border-charcoal-3 bg-charcoal-2': isOpen,
      'border-transparent bg-white text-primary-8 hover:bg-charcoal-1': !isOpen,
    }"
  >
    <template v-if="totalUnread">
      <div
        class="top-6.5 absolute z-[1] h-2.5 w-2.5 transform rounded-full"
        data-testid="bell-pulse"
        :class="{ 'bell-pulse': hasUnread }"
      ></div>
      <div
        class="absolute z-[4] flex shrink-0 items-center justify-center text-white"
        :class="{
          'right-3 top-3 h-5 w-5': totalUnread <= 99,
          'right-2 top-2 h-6 w-6': totalUnread > 99,
        }"
      >
        <div
          class="absolute z-[2] h-full w-full rounded-full bg-red-6"
          data-testid="count-pulse"
          :class="{ 'count-pulse': hasUnread }"
        />
        <p class="z-[3] text-xs">{{ notificationsCount }}</p>
      </div>
    </template>

    <Icon
      icon="mdi:bell-outline"
      class="z-[3]"
      data-testid="bell-icon"
      :class="{ 'animate-bell-color': hasUnread }"
    />
  </button>
</template>

<style #scoped>
.bell-pulse {
  -webkit-animation: bell-pulsing 2s;
  animation: bell-pulsing 2s;
}

.animate-bell-color {
  -webkit-animation: bell-color 2s;
  animation: bell-color 2s;
}

.count-pulse {
  -webkit-animation: count-pulsing 2s;
  animation: count-pulsing 2s;
}

@-webkit-keyframes bell-color {
  0% {
    color: #cb4242;
  }
  100% {
    color: #cb4242;
  }
}

@keyframes bell-color {
  0% {
    color: #cb4242;
  }
  100% {
    color: #cb4242;
  }
}

@-webkit-keyframes count-pulsing {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes count-pulsing {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@-webkit-keyframes bell-pulsing {
  0% {
    background-color: #f5d9d9;
    -webkit-transform: scale(0.5, 0.5);
    transform: scale(0.5, 0.5);
  }
  50% {
    -webkit-transform: scale(4, 4);
    transform: scale(4, 4);
  }
  100% {
    -webkit-transform: scale(0.5, 0.5);
    transform: scale(0.5, 0.5);
  }
}

@keyframes bell-pulsing {
  0% {
    background-color: #f5d9d9;
    -webkit-transform: scale(0.5, 0.5);
    transform: scale(0.5, 0.5);
  }
  50% {
    -webkit-transform: scale(4, 4);
    transform: scale(4, 4);
  }
  100% {
    -webkit-transform: scale(0.5, 0.5);
    transform: scale(0.5, 0.5);
  }
}
</style>
