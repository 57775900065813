<script setup lang="ts" name="OrganisationVesselsIndex">
import { reactive, ref } from 'vue';

import { useService } from '~/features/useService.js';
import { getKeyFromSearch } from '~/features/useUrlSearch';

import Service from '~/services/Service.js';

import VesselsIndex from '~/components/VesselsIndex.vue';

const { organisationId } = defineProps<{
  organisationId: OrganisationId;
}>();

const query = reactive({
  search: getKeyFromSearch('search'),
});

const vessels = ref([]);

const toVesselWithPath = (vessel) => ({
  ...vessel,
  path: {
    name: 'OrganisationVesselsShow',
    params: {
      organisationId: organisationId,
      id: vessel.id,
      name: vessel.name,
    },
  },
});

const serviceState = useService(
  Service.organisation(organisationId).vessels().index(),
  (response) => {
    if (!response.data.length) return false;

    vessels.value = response.data.map(toVesselWithPath);
    return true;
  },
  {
    cancelOnNewRequest: true,
    paginated: true,
    query,
    getQuery: () => ({
      search: query.search,
    }),
  },
);
</script>

<template>
  <VesselsIndex
    v-model="query.search"
    :vessels="vessels"
    :serviceState="serviceState"
  />
</template>
